<template>
  <OrderQuery breadcrumbTitle="Accepted" query="?status=ReadyForDrive" />
</template>

<script>
import OrderQuery from '@/components/order/OrderQuery.vue';

export default {
  name: 'OrderAccepted',
  components: {
    OrderQuery,
  },
};
</script>

<style></style>
